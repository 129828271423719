import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    options_list : [],
    url          :  Urls.catalogPictograms,
}

const module_name = 'catalogPictograms'
const url = Urls.catalogPictograms

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({dispatch, commit, rootState}, data) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'label',
            option_value : 'value',
            add_fields    : ['product','landing', 'filename'],
        }

        return dispatch('app/get_options', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
