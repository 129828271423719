import Vue  from 'vue'
import Urls from '@/../config/url.js'
import moment from 'moment'
import { Message, Loading } from 'element-ui';

const module_name = 'catalogNewProductsItems'
const url = Urls.catalogNewProductsItems


const state = {
    list             : [],
    current          : {},
    data_loading     : false,
    total_count      : 0,
    edit_window      : false,
}

const actions = {

    get_list({dispatch, commit, rootState}, listid){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + listid + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_widget({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'widget/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    check_product_code({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'check_code/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save_list({dispatch, commit, rootState}, data){
        return Vue.axios.put(url + 'save/', data)
            .then((response) => {
                Message({
                    message: 'Данные сохранены',
                    type: 'success'
                });
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    send_list({dispatch, commit, rootState}, data){
        return Vue.axios.put(url + 'send/', data)
            .then((response) => {
                if ('result' in response.data && response.data.result === 'sent')
                    Message({
                        message: 'Данные отправлены логисту',
                        type: 'success'
                    });
                else if ('result' in response.data && response.data.result === 'nochange')
                    Message({
                        message: 'Изменений не было. Данные не отправлены.',
                        type: 'warning'
                    });
                else 
                    Message({
                        message: 'Произошла ошибка. Данные не отправлены.',
                        type: 'error'
                    });
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
