<template>
    <div class="login-page-holder" :style="{'background-image': 'url(' + require('@/assets/images/hp-banner.jpg') + ')'}">
        <div class="login-page-form-holder">
            <div class="login-page-form-title">панель управления для {{$publicurl}}</div>
            <el-form :model="login_form" label-position="left" :rules="rules" ref="loginForm" class="login-page-form">
                <el-form-item label="Логин:" prop="login">
                  <el-input v-model="login_form.login"></el-input>
                </el-form-item>
                <el-form-item label="Пароль:" prop="password">
                    <template slot="label">
                        <div class="login-page-form--password">
                            <div>Пароль:</div>
                            <div class="login-page-form--password-lnk" @click="restore_password">забыли пароль?</div>
                        </div>
                    </template>
                    <el-input v-model="login_form.password" :type="password_input_type"></el-input>
                </el-form-item>

                <div class="login-page-submit">
                    <!-- <el-button type="info" >Восстановить пароль</el-button> -->
                    <div class="spacer" />
                    <el-button type="primary" @click="make_login">Войти</el-button>
                </div>
            </el-form>
        </div>

        <vxe-modal v-model="restore_window" title="Восстановление пароля" class="restore-pass--popup">
            <template #default>
                <el-form :model="restore_form" label-position="left" :rules="restore_rules" ref="restoreForm" class="login-page-form" @submit.native.prevent>
                    <el-form-item label="Логин или email:" prop="login">
                      <el-input v-model="restore_form.login"></el-input>
                    </el-form-item>
                    <el-button type="primary" @click="make_restore" style="margin-top:20px;">Восстановить пароль</el-button>
                </el-form>
            </template>
        </vxe-modal>
    </div>
</template>

<script>

export default {
    data () {
        return {
            login_form: {
                login: '',
                password: '',
            },
            restore_form: {
                login: '',
            },
            rules: {
                login: [
                    { required: true, message: 'Введите логин', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Введите пароль', trigger: 'blur' }
                ],
            },
            restore_rules: {
                login: [
                    { required: true, message: 'Введите логин', trigger: 'blur' }
                ],
            },
            password_input_type: 'password',
            restore_window: false,
        }
    },

    methods: {
        make_login() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.$store.dispatch('app/login', this.$data.login_form).then(() => {
                        this.login_form = {
                            username: '',
                            password: '',
                        }
                    })
                } else {
                    return false;
                }
            });
        },

        listen_keyup(event){
            if (event.keyCode === 13) {
                if (this.restore_window)
                    this.make_restore();
                else
                    this.make_login()
            }
        },

        restore_password(){
            this.restore_window = true;
        },

        make_restore(){
            const $this = this;
            this.$refs.restoreForm.validate((valid) => {
                if (valid) {
                    this.$store.dispatch('app/restore_password_request', this.$data.restore_form).then((resp) => {
                        if (resp.status === 'error')
                            $this.$message.error('Восстановление пароля невозможно. Учетная запись не найдена, либо у учетной записи не указан емейл.');
                        else
                            $this.$message('Новый пароль отправлен на ваш емейл.');
                        this.restore_window = false;
                    })
                } else {
                    return false;
                }
            });
        }
    },

    mounted(){
        this.$nextTick(function () {
            window.addEventListener('keyup', this.listen_keyup);
        })
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.listen_keyup);
    },
}
</script>

<style>
    .login-page-form-holder .el-form--label-left .el-form-item__label {
        display: flex;
        width: 100%;
        float: none;
    }
    .login-page-form--password {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .login-page-form--password-lnk {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
/*         font-size: 12px; */
    }
    .login-page-form--password-lnk:hover {
        text-decoration: underline;
    }

    .restore-pass--popup.vxe-modal--wrapper .vxe-modal--box {
        min-height: 200px;
    }

    .restore-pass--popup.vxe-modal--wrapper.type--modal .vxe-modal--body {
        overflow: hidden;
    }

    .restore-pass--popup.vxe-modal--wrapper.type--modal .vxe-modal--body .vxe-modal--content {
        overflow: hidden;
    }
</style>
