<template>
    <el-menu
        :default-active="$route.path"
        mode="vertical"
        class="menu-nav-menu"
        background-color="#ffffff"
        text-color="#222222"
        active-text-color="#00b0ed"
        unique-opened
        router>

        <template v-for="route in routes">
            <div class="menu-wrapper"  v-if="route.children">
                <el-submenu :index="route.path">
                    <template slot="title">
                        <span>{{route.meta.title}}</span>
                    </template>

                    <template v-for="subroute in route.children">
                        <el-divider content-position="left" v-if="subroute.meta.divider_before">
                            {{subroute.meta.divider_before}}
                        </el-divider>
                        <el-menu-item :index="subroute.path">
                            <a :href="subroute.url" v-if="subroute.url" target="_blank">{{subroute.meta.title}}</a>
                            <div v-else @contextmenu.prevent="$refs.menu.open($event, { routepath: subroute.path })">{{subroute.meta.title}}</div>
                        </el-menu-item>
                    </template>

                </el-submenu>
            </div>

            <div class="menu-wrapper" v-else>
                <el-menu-item :index="route.path">
                    <div @contextmenu.prevent="$refs.menu.open($event, { routepath: route.path })">{{route.meta.title}}</div>
                </el-menu-item>
            </div>
        </template>

<!--         <div class="menu-wrapper" v-if="admin || prog1c">
            <el-submenu index="doc">
                <template slot="title">
                    <span>Документация</span>
                </template>

                <template>
                    <el-menu-item index="rest">
                        <a href="http://docs.nixteam.ru/pub/zj0Cyp/doc/rest" target="_blank">REST API</a>
                    </el-menu-item>
                </template>

            </el-submenu>
        </div> -->

        <vue-context ref="menu">
            <template slot-scope="child">
                <li>
                    <a href="#" @click.prevent="on_context_click($event.target, child.data)">открыть в новом окне</a>
                </li>
            </template>
        </vue-context>
    </el-menu>
</template>


<script>
import { mapState, mapGetters } from 'vuex'    
import VueContext from 'vue-context';

export default {
    components: {
        VueContext
    },
    computed: {
        ...mapState({
            user   : state => state.app.user,
            routes : state => state.app.routes,
        }),
        ...mapGetters({
            admin  : 'app/admin',
            prog1c : 'app/prog1c',
        }),
    },
    methods: {
        on_context_click (event, data) {
            if (data && data.routepath) {
                let route = this.$router.resolve({path: data.routepath});
                window.open(route.href, '_blank');
            }
        }
    }
}
</script>
