<template>
    <div id="app-navbar">
        <div id="sidebar-burger" @click="toggle_sidebar">
            <svg-icon icon-class="bars" />
        </div>
        <div id="header-logo">
            <img src="@/assets/images/logo.svg">
        </div>
        <div id="header-version">
            <div class="header-env">
                <span v-if="$testenv">TEST</span>
                <span v-else>PRODUCTION</span>
            </div>
            <router-link to="/changelog">
                <el-link :underline="false">{{package_version}}</el-link>
            </router-link>
        </div>
        <div class="spacer"></div>
        <div id="header-user" v-if="user">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                    {{user.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="changePass">Изменить пароль</el-dropdown-item>
                    <el-dropdown-item command="logout">Выйти из системы</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <vxe-modal 
            v-model="change_password_window" 
            destroy-on-close
            :dblclickZoom="false"
            size="mini"
            :title="'Изменение пароля'"
            width="40%"
            showFooter>

            <template #default>
                <el-form 
                    label-position="top"
                    :model="change_password_form" 
                    size="small" 
                    ref="PasswordEditor"
                    autocomplete="off" 
                    class="tl-form"
                    :rules="change_password_rules">

                    <el-form-item label="Введите старый пароль" prop="old_password">
                        <el-input v-model="change_password_form.old_password" show-password></el-input>
                    </el-form-item>

                    <el-form-item label="Введите новый пароль" prop="new_password">
                        <el-input v-model="change_password_form.new_password" show-password></el-input>
                    </el-form-item>

                    <el-form-item label="Введите новый пароль еще раз" prop="new_password_repeat">
                        <el-input v-model="change_password_form.new_password_repeat" show-password></el-input>
                    </el-form-item>
                </el-form>
            </template>

            <template #footer>
            <div class="spacer" />
            <el-button 
                type="info" 
                @click="change_password_window=false" 
                size="medium">
                Закрыть
            </el-button>
            <el-button 
                type="primary" 
                @click="submit_change_password"
                size="medium">
                Сохранить
            </el-button>
        </template>
        </vxe-modal>
    </div>
</template>

<script>
import { mapState }  from 'vuex'
const cyrillicPattern = /[а-яА-ЯЁё]/;
export default {
    data(){
        var validate_new_password = (rule, value, callback) => {
            if (value === '' || !value) {
                callback(new Error('Введите новый пароль'));
            }
            else if (cyrillicPattern.test(value)) {
                callback(new Error('Пароль не должен содержать кириллицу'));
            }
            else if (value.length < 6) {
                callback(new Error('Пароль должен быть не меньше 6 символов'));
            } 
            else {
                if (this.change_password_form.new_password_repeat !== '') {
                    this.$refs.PasswordEditor.validateField('new_password_repeat');
                }
                callback();
            }
        };
        var validate_new_password_repeat = (rule, value, callback) => {
            if (value === '' || !value) {
                callback(new Error('Повторите новый пароль'));
            } 
            else if (cyrillicPattern.test(value)) {
                callback(new Error('Пароль не должен содержать кириллицу'));
            }
            else if (value !== this.change_password_form.new_password) {
                callback(new Error('Новый пароль не совпадает с введенным выше!'));
            } 
            else {
                callback();
            }
        };

        return {
            change_password_window: false,
            change_password_form: {},
            change_password_rules: {
                old_password: [
                    { required: true, message: 'Введите старый пароль', trigger: 'blur' },
                ],
                new_password: [
                    { validator: validate_new_password, trigger: 'blur' }
                ],
                new_password_repeat: [
                    { validator: validate_new_password_repeat, trigger: 'blur' }
                ],
            },
        }
    },
    computed: {
        ...mapState({
            sidebar         : state => state.app.sidebar,
            package_version : state => state.app.package_version,
            user            : state => state.app.user,
        }),
    },

    methods: {
        toggle_sidebar(){
            this.$store.dispatch('app/toggle_sidebar')
        },

        handleCommand(command){
            if (command === 'changePass') {
                this.change_password_window = true;
                this.change_password_form = {}
            }
            else if (command === 'logout') {
                this.$store.dispatch('app/logout')
            }
        },

        submit_change_password(){
            this.$refs.PasswordEditor.validate((valid) => {
                if (valid) {
                    this.$store.dispatch('adminUsers/change_password', { oldpass: this.change_password_form.old_password, newpass:this.change_password_form.new_password }).then((response)=>{
                        if ('error' in response) {
                            this.$message({
                                message: 'Произошла ошибка. Неверно введен старый пароль',
                                type: 'error'
                            })
                        }
                        else {
                            this.$message({
                                message: 'Ваш пароль успешно изменен',
                                type: 'success'
                            })
                            this.change_password_window = false
                        }
                    })
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
