import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { firstBy, thenBy } from "thenby"
import { Message } from 'element-ui';

const module_name = 'catalogMarketLaunch'
const url = Urls.catalogMarketLaunch

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
    options_list   : [],
}

var preprocessItem = function(item) {
    delete item.children
    delete item.noedit
    delete item.nodel
    delete item.date
    delete item.author

    item.products = item.products.filter(x => x.code);
    item.products.forEach(record => {
        delete record.name
        delete record.parent_id
        delete record.check_status
        delete record.tooltip
    })

    item.promotion.sort(firstBy("used", {direction:"desc"}).thenBy("done"))
    return item
}

var postprocessList = function(list, rootGetters) {
    list.forEach(item => {
        item = postprocessItem(item, rootGetters)
    })
    return list;
}

var postprocessItem = function(item, rootGetters) {
    item["noedit"] = true;
    item["nodel"] = true;

    if (rootGetters["app/admin"]) {
        item["noedit"] = false;
        item["nodel"] = false;
    }
    else if (rootGetters["app/userid"] === item["author"] || rootGetters["app/userid"] === item["resp_user"]) {
        item["noedit"] = false;
        item["nodel"] = false;
    }
    else if (rootGetters["app/marketolog"] || rootGetters["app/smm"]) {
        item["noedit"] = false;
    }

    return item;
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url         : url,
            name        : module_name,
            postprocess : postprocessList,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
            postprocess : postprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
            postprocess : postprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
        }

        return dispatch('app/get_options', props, { root: true })
    },

    get_product_status({dispatch, commit, rootState}, code){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'product-status/' + code + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    recount({dispatch, commit, rootState}){
        this.data_loading = true
        return Vue.axios.post(url + 'recount/')
        .then((response) => {
            this.data_loading = false
            Message({
                message: 'Прогресс групп товаров пересчитан',
                type: 'success'
            });
            dispatch('get_list')
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
