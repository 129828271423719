import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    options_list   : [],
    roles:[
        {value: 'admin',               label: 'администратор'},
        {value: 'academy',             label: 'менеджер академии'},
        {value: '1c',                  label: 'специалист 1С'},
        {value: 'seo',                 label: 'SEO специалист'},
        {value: 'files_admin',         label: 'администратор закрытого раздела'},
        {value: 'pim_editor',          label: 'редактор PIM'},
        {value: 'pim_moderator',       label: 'модератор PIM'},
        {value: 'marketolog',          label: 'маркетолог'},
        {value: 'director_op',         label: 'руководитель ОП'},
        {value: 'tech_expert',         label: 'технический эксперт'},
        {value: 'developer',           label: 'разработчик'},
        {value: 'smm',                 label: 'SMM'},
    ]
}

const module_name = 'adminUsers'
const url = Urls.adminUsers

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
            add_fields   : ['role', 'active'],
        }

        return dispatch('app/get_options', props, { root: true })
    },

    change_password({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'change_password/' + rootState.app.user.id + '/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
