import Vue  from 'vue'
import Urls from '@/../config/url.js'
import moment from 'moment'
import { Message, Loading } from 'element-ui';


const module_name = 'adminFileManager'
const url = Urls.adminFileManager

const state = {
    resizeInProgress : false,
    resizeLog        : '',
    folders          : [],
    folders_loading  : false
}

const actions = {
    get_folders({dispatch, commit, rootState}){
        commit('set', {type: 'folders_loading', items:true})
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'folders/')
            .then((response) => {
                commit('set', {type: 'folders', items:response.data})
                commit('set', {type: 'folders_loading', items:false})
                resolve(response.data);
            })
            .catch(error => {
                commit('set', {type: 'folders_loading', items:false})
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            commit('set', {type: 'folders_loading', items:false})
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_files({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'files/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    delete_files({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'del-files/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    run_resize({ commit, rootState, dispatch }, entity){
        commit('set', {type: 'resizeLog', items:''})
        commit('set', {type: 'resizeInProgress', items:false})

        return Vue.axios.post(url + 'run-resize/', {entity: entity})
        .then((response) => {
            let body = response.data
            if (body.status && body.status === 'start') {
                commit('set', {type: 'resizeInProgress', items:true})
            }
            else {
                Message({
                    message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                    type: 'error'
                })
            }
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    },

    get_resize_log({commit, dispatch}){
        return Vue.axios.post(url + 'get-resize-log/')
        .then((response) => {
            let body = response.data
            let sip = body.stop ? false : true
            commit('set', {type: 'resizeInProgress', items:sip})
            commit('set', {type: 'resizeLog', items:body.content})
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    },

    search({dispatch, commit, rootState}, term){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'files/search/', {term:term})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_excel({dispatch, commit, rootState}, params){
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'files/excel/', params).then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    }
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
