import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui';

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    viewlist     : {}
}

const url = Urls.settingsSite
const module_name = 'settingsSite'

const actions = {
    get_list({commit, dispatch}){
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true }).then(() => {
            let viewlist = {}
            state.list.forEach(record => {
                viewlist[record.key] = record.value
            })
            commit('set', {type: 'viewlist', items:viewlist})
        })
    },

    save_list({commit, dispatch}){
        return Vue.axios.put(url, state.viewlist).then((response) => {
            Message({
                message: 'Данные сохранены',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    }
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
