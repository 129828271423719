import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'pricelistsDynamicBlocks'
const url = Urls.pricelistsDynamicBlocks

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    options_list : [],
}

var preprocessItem = function(item) {
    delete item.children

    item.sets.forEach(f => {
        delete f.image_link

        if (!f.source_prod || f.source_prod === '')
            f.source_prod = null;
    })
    item.groups.forEach(f => {
        delete f.image_link
        delete f.image_link2
        delete f.image_link3
        delete f.image_link4
        delete f.image_link5
        delete f.image_link6

        if (!f.image || f.image === '')
            f.image = null;
        if (!f.image2 || f.image2 === '')
            f.image2 = null;
        if (!f.image3 || f.image3 === '')
            f.image3 = null;
        if (!f.image4 || f.image4 === '')
            f.image4 = null;
        if (!f.image5 || f.image5 === '')
            f.image5 = null;
        if (!f.image6 || f.image6 === '')
            f.image6 = null;
        if (!f.source_prod || f.source_prod === '')
            f.source_prod = null;
    })

    return item
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url + rootState.pricelistsDynamic.current.id + '/',
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_categories({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/categories/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_series({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/series/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_products({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/products/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
