import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'promoslidesSets'
const url = Urls.promoslidesSets
const images_url = Urls.promoslidesSets + 'images/'

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_promoslides_set({dispatch, commit, rootState}, set_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + set_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
        }

        return dispatch('app/get_options', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
