import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui';

const module_name = 'projects'
const url = Urls.projects
const images_url = Urls.projects + 'images/'

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
    images_url     :  images_url,
    images_list    : [],
    images_loading : false,
    options_list   : [],
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_images_list({dispatch, commit, rootState}, record_id){
        let props = {
            url     : images_url + record_id + '/',
            name    : module_name,
        }

        return dispatch('app/get_images_list', props, { root: true })
    },

    delete_image({dispatch, commit, rootState}, data) {
        let props = {
            url  : images_url,
            name : module_name,
            data : data
        }

        return dispatch('app/delete_image', props, { root: true })
    },

    update_image({dispatch, commit, rootState}, item){
        let props = {
            url         : images_url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_image', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'title',
        }

        return dispatch('app/get_options', props, { root: true })
    },

    download_excel({dispatch, commit, rootState}) {
        let props = {
            url         : url,
            name        : module_name,
        }

        return dispatch('app/download_excel', props, { root: true })
    },

    get_moderation_list({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'moderation-list/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_project_full({dispatch, commit, rootState}, project_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'full/' + project_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save({dispatch, commit, rootState}, data){
        return Vue.axios.put(url + 'save/' + data.id + '/', data)
            .then((response) => {
                if (response.data.error_url)
                    Message({
                        message: `Данные не были сохранены. Указана ссылка, используемая в другом проекте`,
                        type: 'error'
                    });
                else if (response.data.changes)
                    Message({
                        message: 'Данные проекта сохранены в карточку',
                        type: 'success'
                    });
                else
                    Message({
                        message: 'Изменений не было',
                        type: 'success'
                    });
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
