import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'academyCategory'
const url = Urls.academyCategory
const images_url = Urls.academyCategory + 'images/'

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
    options_list   : [],
    images_url         :  images_url,
    images_list        : [],
    images_loading     : false,
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'title',
        }

        return dispatch('app/get_options', props, { root: true })
    },

    get_images_list({dispatch, commit, rootState}, record_id){
        let props = {
            url     : images_url + record_id + '/',
            name    : module_name,
        }

        return dispatch('app/get_images_list', props, { root: true })
    },

    delete_image({dispatch, commit, rootState}, data) {
        let props = {
            url  : images_url,
            name : module_name,
            data : data
        }

        return dispatch('app/delete_image', props, { root: true })
    },

    update_image({dispatch, commit, rootState}, item){
        let props = {
            url         : images_url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_image', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
