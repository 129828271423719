import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message, Loading } from 'element-ui';

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    page_current   : 1,
    page_size      : 50,
    sort_field     : undefined,
    sort_direction : undefined,
    filters        : {},
    partners: false,
    moderation: true,
}

const module_name = 'shopUsers'
const url = Urls.shopUsers

var preprocessItem = function(item) {
    item = { 
        "blocked"                 : item.blocked,
        "surname"                 : item.surname,
        "name"                    : item.name,
        "patronymic"              : item.patronymic,
        "phone"                   : item.phone,
        "email"                   : item.email,
        "city"                    : item.city,
        "privileges"              : item.privileges,
        "can_verify_price"        : item.can_verify_price,
        "can_edit_kp"             : item.can_edit_kp,
        "ispring_email"           : item.ispring_email,
        "academy_code"            : item.academy_code,
        "position"                : item.position,
        "warehouse_id"            : item.warehouse_id,
        "header_rights"           : item.header_rights,
        "dealer_access_confirmed" : item.dealer_access_confirmed,
        "pim_user"                : item.pim_user,
        "ispring_email_verified"  : item.ispring_email_verified,
        "email_verified"          : item.email_verified,
        "ecomm"                   : item.ecomm,
    }

    return item
}

const actions = {
    get_paginated_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_paginated_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    download_excel({dispatch, commit, rootState}) {
        let props = {
            url         : url,
            name        : module_name,
        }

        return dispatch('app/download_excel', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
            add_fields   : ['phone', 'dealer_id'],
        }

        return dispatch('app/get_options', props, { root: true })
    },

    find_user({dispatch, commit, rootState}, query){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/find/', {query: query})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    find_users({dispatch, commit, rootState}, ids){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/find-many/', {ids:ids})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_user({dispatch, commit, rootState}, id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + '/get-user/' + id + '/',)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    add_user_short({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/short/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    download_excel_courses({dispatch, commit, rootState}) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.get(url + 'excel-courses-download/').then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },

    cancel_sessions({dispatch, commit, rootState}, user_id){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + '/cancel-sessions/', {user_id:user_id})
            .then((response) => {
                Message("Сессии удалены")
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_ecomm_list({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'ecomm-list/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
