import Vue from 'vue'
import App from './views/App'
import router from './router'
import store from './store'

import { publichost } from '@/../config/host.js'
Vue.prototype.$publichost = publichost
import { uploadshost } from '@/../config/host.js'
Vue.prototype.$uploadshost = uploadshost
import { publicurl } from '@/../config/host.js'
Vue.prototype.$publicurl = publicurl

import { testenv, sentrydsn } from '@/../config/conf.js'
Vue.prototype.$testenv = testenv

import 'normalize.css/normalize.css'
import '@/assets/styles/index.scss'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'
Vue.use(ElementUI, { locale });

import moment           from 'moment-timezone'
import 'moment/locale/ru'
moment.tz.setDefault('Europe/Moscow')
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment

import InputFacade from 'vue-input-facade'
Vue.use(InputFacade)

import './directives/icons'

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import uploader from 'vue-simple-uploader'
Vue.use(uploader)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
Vue.use(vueFilterPrettyBytes)

import VueProgressiveImage from 'vue-progressive-image'
Vue.use(VueProgressiveImage)

Vue.use(require('vue-codemirror-lite'))
require('codemirror/mode/javascript/javascript')
require('codemirror/addon/hint/show-hint.js')
require('codemirror/addon/hint/show-hint.css')
require('codemirror/addon/hint/javascript-hint.js')

import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import ruRU from '@/utils/vxeRu'
// import enUS from 'vxe-table/lib/locale/lang/en-US'

import Vue2OrgTree from '@/components/OrgTree'
Vue.use(Vue2OrgTree)

// import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
// VXETable.use(VXETablePluginExportXLSX)
VXETable.formats.mixin({
    format_date ({ cellValue }) {
        if (!cellValue) return '';
        let vl;
        if (typeof(cellValue) === 'string' && cellValue.length == 10) {
            vl = moment(cellValue)
        }
        else {
            vl = moment(cellValue).utcOffset('-0000')
        }

        return vl.format('DD.MM.YY')
    },
    format_datetime ({ cellValue }) {
        if (!cellValue) return '';

        let vl;
        if (typeof(cellValue) === 'string' && cellValue.length == 10) {
            vl = moment(cellValue)
        }
        else {
            vl = moment(cellValue).utcOffset('-0000')
        }
        if (vl.format('HH:mm') === '00:00')
            return vl.format('DD.MM.YY');
        return vl.format('DD.MM.YY HH:mm')
    },
    no_zero_int ({ cellValue }) {
        return (cellValue) ? cellValue : null
    },
    format_bool ({ cellValue }) {
        return (cellValue) ? "да" : "нет"
    },
})
Vue.use(VXETable, {
    i18n: key => XEUtils.get(ruRU, key)
})

Vue.config.productionTip = false

import * as Sentry from "@sentry/vue";
Sentry.init({
    Vue,
    dsn: sentrydsn,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV !== 'development',
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
