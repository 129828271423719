<template>
    <div>
        <div v-if="is_authed" id="app-container" v-loading="app_loading">
            <div id="app-sidebar" v-if="sidebar"><Sidebar /></div>
            <div id="app-holder" v-bind:class="{ sidebared: sidebar }">
                <div id="app-header"><Navbar /></div>
                <div id="app-body"><router-view :key="$route.fullPath" /></div>
            </div>
        </div>
        <div v-else-if="$route.name === 'auth_by_hash'"><router-view :key="$route.fullPath" /></div>
        <div v-else><Login /></div>
    </div>
</template>

<script>
import Login from './App/Login.vue'
import Sidebar from './App/Sidebar.vue'
import Navbar from './App/Navbar.vue'
import { mapState } from 'vuex'

export default {
    components: {
        Login,
        Sidebar,
        Navbar,
    },

    computed: {
        ...mapState({
            is_authed   : state => state.app.is_authed,
            sidebar     : state => state.app.sidebar,
            app_loading : state => state.app.app_loading,
            user        : state => state.app.user,
        }),
    },

    created: function() {
        this.$store.dispatch('app/check_auth')
    },

    watch:{
        $route (to, from){
            if (!to.meta || !to.meta.roles){
                return
            }

            if (!this.user.role) {
                return
            }
            let allowed = to.meta.roles.filter(i => this.user.role.includes(i))
            if (!allowed.length)
                this.$router.push('/')
        },

        user (to, from){
            if (!this.user.role) {
                return
            }
            else if (!this.$route.meta) {
                return
            }
            else if (!this.$route.meta.roles) {
                return
            }
            else {
                let allowed = this.$route.meta.roles.filter(i => this.user.role.includes(i))
                if (!allowed.length)
                    this.$router.push('/');
            }
        },
    }
}
</script>
