import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    page_current   : 1,
    page_size      : 50,
    sort_field     : undefined,
    sort_direction : undefined,
    filters        : {},
}

const module_name = 'shopOrders'
const url = Urls.shopOrders

const actions = {
    get_paginated_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_paginated_list', props, { root: true })
    },

    download_excel({dispatch, commit, rootState}) {
        let props = {
            url         : url,
            name        : module_name,
        }

        return dispatch('app/download_excel', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
