import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message, Loading } from 'element-ui';

const module_name = 'academyCourseOnline'
const url = Urls.academyCourseOnline
const images_url = Urls.academyCourseOnline + 'images/'

const state = {
    list               : [],
    current            : {},
    data_loading       : false,
    total_count        : 0,
    edit_window        : false,
    images_url         :  images_url,
    images_list        : [],
    images_loading     : false,
    downloadInProgress : false,
    downloadLog        : '',
    options_list       : [],
}

var preprocessItem = function(item) {
    let extra = ['ispring_id','title','description','ispring_view_url','trainer','date_created','date_updated']
    extra.forEach(f => {
        delete item[f]
    })
    return item
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    get_images_list({dispatch, commit, rootState}, record_id){
        let props = {
            url     : images_url + record_id + '/',
            name    : module_name,
        }

        return dispatch('app/get_images_list', props, { root: true })
    },

    delete_image({dispatch, commit, rootState}, data) {
        let props = {
            url  : images_url,
            name : module_name,
            data : data
        }

        return dispatch('app/delete_image', props, { root: true })
    },

    update_image({dispatch, commit, rootState}, item){
        let props = {
            url         : images_url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_image', props, { root: true })
    },

    run_download({ commit, rootState, dispatch }, entity){
        commit('set', {type: 'downloadLog', items:''})
        commit('set', {type: 'downloadInProgress', items:false})

        return Vue.axios.post(url + 'run-download/', {entity: entity})
        .then((response) => {
            let body = response.data
            if (body.status && body.status === 'start') {
                commit('set', {type: 'downloadInProgress', items:true})
            }
            else {
                Message({
                    message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                    type: 'error'
                })
            }
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    },

    get_download_log({commit, dispatch}){
        return Vue.axios.post(url + 'get-download-log/')
        .then((response) => {
            let body = response.data
            let sip = body.stop ? false : true
            commit('set', {type: 'downloadInProgress', items:sip})
            commit('set', {type: 'downloadLog', items:body.content})
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'title',
        }

        return dispatch('app/get_options', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
