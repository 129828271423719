import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    attributes   : [],
    options_list : [],
}

const module_name = 'adminAttributesDealers'
const url = Urls.adminAttributesDealers

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    get_options({commit, dispatch, rootState}){
        let props = {
            url     : url,
            name    : module_name,
            query_params : {
                fields: ['field','name','xls'],
                find: {'editors': true},
            },
            option_title: 'name',
            add_fields: ['xls']
        }

        return dispatch('app/get_options', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_fields({dispatch, commit, rootState}, type){
        let params = {find: {}}
        params.find[type] = true

        let query_params = {
            where: JSON.stringify(params)
        }

        return new Promise((resolve, reject) => {
            Vue.axios.get(url, {params: query_params})
            .then((response) => {
                resolve(response);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
