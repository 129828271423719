import Vue  from 'vue'
import Urls from '@/../config/url.js'
import XEUtils from 'xe-utils'

const module_name = 'catalogCategory'
const url = Urls.catalogCategory
const images_url = Urls.catalogCategory + 'images/'

const state = {
    list                 : [],
    current              : {},
    data_loading         : false,
    total_count          : 0,
    edit_window          : false,
    images_url           :  images_url,
    images_list          : [],
    images_loading       : false,
    options_parents_list : [],
    options_all_list     : [],
    options_all_names    : [],
    options_all_tree     : [],
    options_all_children : [],
    options_parents_site_list : [],
}

var preprocessItem = function(item) {
    delete item.children
    delete item.products_qty
    item.selection_master_filters = item.selection_master_filters.filter(function( obj ) {
        return obj.active;
    });
    item.selection_master_filters.forEach(f => {
        delete f.filter_variant
        delete f.name
        delete f.units
    })
    item.catalog_filters = item.catalog_filters.filter(function( obj ) {
        return obj.active;
    });
    item.catalog_filters.forEach(f => {
        delete f.filter_variant
        delete f.name
        delete f.units
    })
    return item
}

const actions = {
    get_list({commit, dispatch, rootState}, query_params) {
        let props = {
            url          : url,
            name         : module_name,
            query_params : query_params
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_images_list({dispatch, commit, rootState}, record_id){
        let props = {
            url     : images_url + record_id + '/',
            name    : module_name,
        }

        return dispatch('app/get_images_list', props, { root: true })
    },

    delete_image({dispatch, commit, rootState}, data) {
        let props = {
            url  : images_url,
            name : module_name,
            data : data
        }

        return dispatch('app/delete_image', props, { root: true })
    },

    update_image({dispatch, commit, rootState}, item){
        let props = {
            url         : images_url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_image', props, { root: true })
    },

    get_options({commit, dispatch, rootState}){
        let props = {
            url          : url,
            name         : module_name,
            query_params : {fields: ['name','parent_id','sortn','url', 'hidden']}
        }

        return dispatch('app/get_list', props, { root: true }).then(() => {
            let init_list = JSON.parse(JSON.stringify(state.list))
            let tree = XEUtils.toArrayTree(init_list, { key: 'id', parentKey: 'parent_id', sortKey: 'sortn', reverse: false });
            
            let options_parents_list      = []
            let options_parents_site_list = []
            let options_all_list          = []
            let options_all_tree          = []
            let options_all_names         = []
            let options_all_children      = []
            tree.forEach(item => {
                options_parents_list.push({ value:  item.id, label: item.name})
                if (!item.hidden)
                    options_parents_site_list.push({ value:  item.id, label: item.name});

                options_all_list.push({ value:  item.id, label: item.name})
                let options_tree_item = {
                    value    : item.id,
                    label    : item.name,
                    parking  : (item.url === 'parking-systems') ? true : false,
                    children : []
                };
                if (item.children) {
                    item.children.forEach(subitem => {
                        options_all_list.push({ value:  subitem.id, label: item.name + ' / ' + subitem.name})
                        options_all_children.push({ value:  subitem.id, label: item.name + ' / ' + subitem.name})
                        options_all_names.push({ value:  subitem.id, label: subitem.name})
                        options_tree_item.children.push({value: subitem.id, label: subitem.name});
                    })
                }
                options_all_tree.push(options_tree_item)
            })
            commit('set', {type: 'options_parents_list', items: options_parents_list});
            commit('set', {type: 'options_all_list', items: options_all_list});
            commit('set', {type: 'options_all_tree', items: options_all_tree});
            commit('set', {type: 'options_all_names', items: options_all_names});
            commit('set', {type: 'options_all_children', items: options_all_children});
            commit('set', {type: 'options_parents_site_list', items: options_parents_site_list});
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
