import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'dealersAddReasons'
const url = Urls.dealersAddReasons

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
    options_list   : []
}

const actions = {
    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
            add_fields   : ['admin_only'],
        }

        return dispatch('app/get_options', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
