import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message, Loading } from 'element-ui';

const module_name = 'catalogSiteCategories'
const url = Urls.catalogSiteCategories

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    options_list : [],
    url          : url,
}

var preprocessItem = function(item) {
    let extra = ['manual_products_qty', 'products_list']
    extra.forEach(f => {
        delete item[f]
    })
    return item
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({dispatch, commit, rootState}, data) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
            add_fields   : ['auto'],
        }

        return dispatch('app/get_options', props, { root: true })
    },

    run_apply({ commit, rootState, dispatch }){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'run-apply/')
            .then((response) => {
                let body = response.data
                if (body.status && body.status === 'start') {
                    resolve(true);
                }
                else {
                    Message({
                        message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                        type: 'error'
                    })
                    resolve(false);
                }
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_apply_log({commit, dispatch}){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'get-apply-log/')
            .then((response) => {
                let body = response.data
                let sip = body.stop ? false : true
                resolve({ run_in_progress: sip, content: body.content })
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
